// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessories-js": () => import("./../../../src/pages/accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-estimation-js": () => import("./../../../src/pages/estimation.js" /* webpackChunkName: "component---src-pages-estimation-js" */),
  "component---src-pages-handbags-js": () => import("./../../../src/pages/handbags.js" /* webpackChunkName: "component---src-pages-handbags-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-products-js": () => import("./../../../src/pages/our-products.js" /* webpackChunkName: "component---src-pages-our-products-js" */),
  "component---src-pages-recycling-js": () => import("./../../../src/pages/recycling.js" /* webpackChunkName: "component---src-pages-recycling-js" */),
  "component---src-pages-shipping-js": () => import("./../../../src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-pages-toys-js": () => import("./../../../src/pages/toys.js" /* webpackChunkName: "component---src-pages-toys-js" */),
  "component---src-pages-used-clothes-js": () => import("./../../../src/pages/used-clothes.js" /* webpackChunkName: "component---src-pages-used-clothes-js" */),
  "component---src-pages-used-shoes-js": () => import("./../../../src/pages/used-shoes.js" /* webpackChunkName: "component---src-pages-used-shoes-js" */),
  "component---src-pages-vintage-js": () => import("./../../../src/pages/vintage.js" /* webpackChunkName: "component---src-pages-vintage-js" */)
}

